import React, { memo } from 'react'
import { useInterval, useMeasure, useStateList } from 'react-use'

import { useApp } from 'store/AppStore'

import { FrameContainer, Root } from './PngSequencer.styled.js'

import spritesheets from 'assets/spritesheets'

const PngSequencer = ({ path, selected, visible, frameW, frameH, numFrames = 101 }) => {
  const { loadInc } = useApp()

  const [ref, { width, height }] = useMeasure()

  const pos = new Array(numFrames).fill('').map((_, id) => id)

  const { state, next } = useStateList(pos)

  useInterval(
    () => {
      next()
    },
    selected && visible ? 1000 / 30 : null
  )

  const containerScale = Math.min(width / frameW, height / frameH)

  return (
    <Root ref={ref}>
      <div className="Inner">
        <FrameContainer style={{ width: frameW * containerScale, height: frameH * containerScale }}>
          <img
            src={spritesheets[path]}
            alt=""
            style={{ transform: `translate(${-state * frameW * containerScale}px,0)` }}
            onLoad={() => loadInc(1)}
          />
        </FrameContainer>
      </div>
    </Root>
  )
}

export default memo(PngSequencer)
