const data = [
	{
		name: 'pmi',
		timeline: [
			{
				id: 1,
				year: '1998-99',
				spritesheet: 'earth_pins',
				frame: {
					width: 280,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: `Lancement d'Accord aux États-Unis (connu sous le nom d'Oasis au Japon).`,
				},
			},
			{
				id: 3,
				year: '2003',
				spritesheet: 'cog_wheel',
				frame: {
					width: 315,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: 'PMI développe la R&D sur les produits sans fumée.',
				},
			},
			{
				id: 4,
				year: '2004',
				meta: {
					list: [
						`Neuchâtel sélectionné pour la R&D (Le cube).`,
						`Centre pilote de développement industriel.`,
					],
				},
				spritesheet: 'building',
				frame: { width: 378, height: 448, numFrames: 125 },
			},
			{
				id: 5,
				year: '2006',
				spritesheet: 'heatbar',
				frame: {
					width: 400,
					height: 640,
					numFrames: 100,
				},
				meta: {
					text: 'Lancement de Heatbar par PMI en Suisse et en Australie.',
				},
			},
			{
				id: 2,
				year: '2008',
				spritesheet: 'arrows',
				frame: {
					width: 315,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: 'Spin off de PMI par Altria (& PM USA).',
				},
			},
			{
				id: 6,
				year: '2014',
				spritesheet: 'italy',
				frame: { width: 420, height: 448, numFrames: 100 },
				meta: {
					list: [
						`Lancement d'IQOS au Japon et en Italie.`,
						`Investissement dans une nouvelle usine sans fumée en Italie.`,
					],
				},
			},
			{
				id: 7,
				year: '2015',
				spritesheet: 'sticks',
				frame: {
					width: 280,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: `Lancement d'IQOS en Suisse et disponibilité dans 5 autres pays.`,
				},
			},
			{
				id: 11,
				year: '2016',
				spritesheet: 'loader',
				frame: { width: 448, height: 448, numFrames: 125 },
				meta: {
					list: [
						`IQOS est disponible dans 19 pays.`,
						`Plus d'un million de clients.`,
					],
				},
			},
			{
				id: 12,
				year: '2017',
				meta: {
					list: [
						`IQOS est disponible dans plus de 25 pays.`,
						`Plus de 3 millions de clients.`,
					],
				},
				spritesheet: 'earth_arrow',
				frame: {
					width: 420,
					height: 448,
					numFrames: 125,
				},
			},
			{
				id: 13,
				year: '2021',
				spritesheet: 'flags',
				frame: {
					width: 350,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: `Lancement de la technologie d'induction Iluma en Suisse, au Japon et en Espagne.`,
				},
			},
			{
				id: 14,
				year: '2023',
				spritesheet: 'fire',
				frame: { width: 224, height: 448, numFrames: 125 },
				meta: {
					list: [
						`Les produits sans fumée de PMI sont disponibles à la vente dans 80 marchés.`,
						`Les produits antitabac ont représenté 35% des recettes nettes au 1er trimestre 23.`,
						`On estime à 19,4 millions le nombre d'adultes qui sont passés à IQOS et ont arrêté de fumer.`,
					],
				},
			},
		],
	},
	{
		name: 'mdc',
		timeline: [
			{
				id: 15,
				year: 'Début 2000',
				spritesheet: 'smoke',
				frame: { width: 280, height: 448, numFrames: 125 },
				meta: {
					text: `Exploration sensorielle de sticks chauffés de différentes coupes de tabac à l'aide d'un simulateur de fumée.`,
				},
			},
			{
				id: 16,
				year: '2003-2004',
				spritesheet: 'equip',
				frame: { width: 385, height: 448, numFrames: 125 },
				meta: {
					text: `Expérience sensorielle prometteuse identifiée lors de l'utilisation de produits du tabac chauffés.`,
				},
			},
			{
				id: 17,
				year: '2004',
				spritesheet: 'microscope',
				frame: { width: 287, height: 448, numFrames: 100 },
				meta: {
					list: [
						`Décision de développer des solutions sans fumée avec un groupe de 30 scientifiques.`,
						`Location de laboratoires et de locaux-pilotes à Yverdon.`,
					],
				},
			},
			{
				id: 19,
				year: '2006',
				spritesheet: 'leaf',
				frame: { width: 224, height: 448, numFrames: 125 },
				meta: {
					text: `Installation d'une ligne de feuilles moulées à Yverdon.`,
				},
			},
			{
				id: 20,
				year: '2009',
				spritesheet: 'building',
				frame: { width: 378, height: 448, numFrames: 125 },
				meta: {
					list: [
						`Déménagement dans les bâtiments du Cube et du Centre de Développement Industriel à Neuchâtel.`,
						`Déménagement des activités de prototypage d'Yverdon dans le bâtiment du Centre de Développement Industriel nouvellement construit.`,
						`Premier prototypage d'une sertisseuse de tabac.`,
						`Installation du premier peigneur semi-industriel.`,
					],
				},
			},
			{
				id: 21,
				year: '2011',
				spritesheet: 'robotic_arm',
				frame: { width: 287, height: 448, numFrames: 100 },
				meta: {
					text: `Installation de la ligne d'emballage du prototype de paquet de tabac en bâtonnets.`,
				},
			},
			{
				id: 22,
				year: '2012',
				spritesheet: 'puzzle',
				frame: { width: 315, height: 448, numFrames: 125 },
				meta: {
					text: `Installation d'une machine à combiner flexible pour explorer différentes constructions de bâtonnets de tabac.`,
				},
			},
			{
				id: 23,
				year: '2013',
				spritesheet: 'tester',
				frame: { width: 385, height: 448, numFrames: 125 },
				meta: {
					list: [
						`Installation de la première sertisseuse semi-industrielle.`,
						`Première production commerciale pour les lancements en ville (Japon et Italie).`,
					],
				},
			},
			{
				id: 24,
				year: '2015 Mai',
				meta: {
					text: `Première production commerciale de bâtonnets de tabac chauffés pour le marché suisse.`,
				},
				spritesheet: 'stick',
				frame: { width: 315, height: 448, numFrames: 100 },
			},
			{
				id: 25,
				year: '2016 Novembre',
				spritesheet: 'truck',
				frame: { width: 448, height: 448, numFrames: 125 },
				meta: {
					text: `Le Centre de développement industriel franchit le cap des 100 millions de bâtonnets de tabac pour la production commerciale.`,
				},
			},
			{
				id: 26,
				year: '2017',
				meta: {
					list: [
						`Fin de la production commerciale.`,
						`Le Centre de développement industriel reprend ses activités de prototypage et de vérification des technologies à 100%.`,
					],
				},
				spritesheet: 'lighthouse',
				frame: { width: 140, height: 448, numFrames: 100 },
			},
		],
	},
]

export default data
