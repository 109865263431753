const data = [
	{
		name: 'pmi',
		timeline: [
			{
				id: 1,
				year: '1998-99',
				spritesheet: 'earth_pins',
				frame: {
					width: 280,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: 'Accord wird in den USA eingeführt (in Japan als Oasis bekannt).',
				},
			},
			{
				id: 3,
				year: '2003',
				spritesheet: 'cog_wheel',
				frame: {
					width: 315,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: 'PMI erweitert die F&E für rauchfreie Produkte.',
				},
			},
			{
				id: 4,
				year: '2004',
				meta: {
					list: [
						`Neuenburg wird für F&E ausgewählt (Cube).`,
						`Pilotzentrum für industrielle Entwicklung.`,
					],
				},
				spritesheet: 'building',
				frame: { width: 378, height: 448, numFrames: 125 },
			},
			{
				id: 5,
				year: '2006',
				spritesheet: 'heatbar',
				frame: {
					width: 400,
					height: 640,
					numFrames: 100,
				},
				meta: {
					text: 'Einführung des Heatbar durch PMI in der Schweiz und in Australien.',
				},
			},
			{
				id: 2,
				year: '2008',
				spritesheet: 'arrows',
				frame: {
					width: 315,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: 'Ausgliederung von PMI aus Altria (& PM USA).',
				},
			},
			{
				id: 6,
				year: '2014',
				spritesheet: 'italy',
				frame: { width: 420, height: 448, numFrames: 100 },
				meta: {
					list: [
						`Markteinführung von IQOS in Japan und Italien.`,
						`Investition in eine neue rauchfreie Fabrik in Italien.`,
					],
				},
			},
			{
				id: 7,
				year: '2015',
				spritesheet: 'sticks',
				frame: {
					width: 280,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text:
						'IQOS wird in der Schweiz eingeführt und ist in 5 weiteren Ländern erhältlich.',
				},
			},
			{
				id: 11,
				year: '2016',
				spritesheet: 'loader',
				frame: { width: 448, height: 448, numFrames: 125 },
				meta: {
					list: [
						'IQOS ist in 19 Ländern verfügbar.',
						'Mehr als 1 Million Konsumenten.',
					],
				},
			},
			{
				id: 12,
				year: '2017',
				meta: {
					list: [
						'IQOS ist in mehr als 25 Ländern verfügbar.',
						'Mehr als 3 Millionen Konsumenten.',
					],
				},
				spritesheet: 'earth_arrow',
				frame: {
					width: 420,
					height: 448,
					numFrames: 125,
				},
			},
			{
				id: 13,
				year: '2021',
				spritesheet: 'flags',
				frame: {
					width: 350,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text:
						'Einführung der Iluma-Induktionstechnologie in der Schweiz, Japan und Spanien.',
				},
			},
			{
				id: 14,
				year: '2023',
				spritesheet: 'fire',
				frame: { width: 224, height: 448, numFrames: 125 },
				meta: {
					list: [
						"PMI's rauchfreie Produkte sind in 80 Märkten zum Kauf erhältlich.",
						'Rauchfreie Produkte machen 35% des Nettoumsatzes im Q1 23 aus.',
						'Schätzungsweise 19,4 Millionen Erwachsene sind auf IQOS umgestiegen und haben mit dem Rauchen aufgehört.',
					],
				},
			},
		],
	},
	{
		name: 'mdc',
		timeline: [
			{
				id: 15,
				year: 'Anfang 2000',
				spritesheet: 'smoke',
				frame: { width: 280, height: 448, numFrames: 125 },
				meta: {
					text:
						'Sensorische Erkundung von erhitzten Tabaksträngen mit verschiedenen Tabakschnittfüllungen mittels eines Rauchsimulators.',
				},
			},
			{
				id: 16,
				year: '2003-2004',
				spritesheet: 'equip',
				frame: { width: 385, height: 448, numFrames: 125 },
				meta: {
					text:
						'Identifizierung vielversprechender sensorischer Erfahrungen mit erhitzten Tabakprodukten.',
				},
			},
			{
				id: 17,
				year: '2004',
				spritesheet: 'microscope',
				frame: { width: 287, height: 448, numFrames: 100 },
				meta: {
					list: [
						'Entscheidung wird getroffen, mit einer Gruppe von 30 Wissenschaftlern rauchfreie Lösungen zu entwickeln.',
						'Anmietung von Labors und einer Pilotfläche in Yverdon.',
					],
				},
			},
			{
				id: 19,
				year: '2006',
				spritesheet: 'leaf',
				frame: { width: 224, height: 448, numFrames: 125 },
				meta: {
					text: 'Installation einer Cast Leaf-Linie in Yverdon.',
				},
			},
			{
				id: 20,
				year: '2009',
				spritesheet: 'building',
				frame: { width: 378, height: 448, numFrames: 125 },
				meta: {
					list: [
						`Umzug in die Gebäude des Cube und des industriellen Entwicklungszentrums in Neuenburg.`,
						`Verlegung der Prototyping-Aktivitäten von Yverdon in das neu errichtete Gebäude des industriellen Entwicklungszentrum.`,
						`Erster Prototyp der Tabakpressmaschine.`,
						`Installation des ersten halbindustriellen Kombinators.`,
					],
				},
			},
			{
				id: 21,
				year: '2011',
				spritesheet: 'robotic_arm',
				frame: { width: 287, height: 448, numFrames: 100 },
				meta: {
					text: 'Installation der Prototyp-Verpackungslinie für Tabaksticks.',
				},
			},
			{
				id: 22,
				year: '2012',
				spritesheet: 'puzzle',
				frame: { width: 315, height: 448, numFrames: 125 },
				meta: {
					text:
						'Installation einer flexiblen Kombiniermaschine zur Erprobung verschiedener Tabakstangenkonstruktionen.',
				},
			},
			{
				id: 23,
				year: '2013',
				spritesheet: 'tester',
				frame: { width: 385, height: 448, numFrames: 125 },
				meta: {
					list: [
						`Installation der ersten halbindustriellen Pressmaschine.`,
						`Erste kommerzielle Produktion für die Lancierung in Städten (Japan & Italien).`,
					],
				},
			},
			{
				id: 24,
				year: '2015 Mai',
				meta: {
					text:
						'Erste kommerzielle Produktion von erhitzbaren Tabaksticks für den Schweizer Markt.',
				},
				spritesheet: 'stick',
				frame: { width: 315, height: 448, numFrames: 100 },
			},
			{
				id: 25,
				year: '2016 November',
				spritesheet: 'truck',
				frame: { width: 448, height: 448, numFrames: 125 },
				meta: {
					text:
						'Das industrielle Entwicklungszentrum überschreitet die Marke von 100 Millionen Tabaksticks für die kommerzielle Produktion.',
				},
			},
			{
				id: 26,
				year: '2017',
				meta: {
					list: [
						'Ende der kommerziellen Produktion.',
						'Das industrielle Entwicklungszentrum widmet sich wieder zu 100% dem Prototypenbau und der Technologieverifizierung.',
					],
				},
				spritesheet: 'lighthouse',
				frame: { width: 140, height: 448, numFrames: 100 },
			},
		],
	},
]

export default data
