import { createContext, useContext, useState, useCallback } from 'react'
import { useCounter, useMedia, useSearchParam } from 'react-use'

import languages from './languages'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const AppContext = createContext()

const { Provider } = AppContext

const metaTags = {
	en: {
		title: 'PMI Science',
		description: 'Over 30 years of inovation',
	},
	de: {
		title: 'PMI-Wissenschaft',
		description: 'Über 30 Jahre Innovation',
	},
	fr: {
		title: 'Sciences PMI',
		description: `Plus de 30 ans d'innovation`,
	},
}

const groupPeriods = (data) => {
	return data
		.reduce((res, { timeline }) => {
			return [...res, ...timeline]
		}, [])
		.filter((p) => p.spritesheet !== undefined)
}

const AppProvider = ({ children }) => {
	const [selectedGroupIndex, setSelectedGroupIndex] = useState(1)
	const [selectedPeriodIndex, setSelectedPeriodIndex] = useState([0, 0])
	const [loadCount, { inc }] = useCounter(0)

	const lang = useSearchParam('lang')

	const data = languages[lang || 'en']
	const periods = groupPeriods(data)

	const isShort = useMedia(`(max-height: 950px)`)
	const isPortrait = useMedia(`(max-width: 1023px)`)
	const altLayout = useMedia(`(max-height: 760px) and (orientation: landscape)`)

	const loadInc = useCallback(
		(val) => {
			inc(val)
		},
		[inc]
	)

	const setSelectedGroup = useCallback(
		(index) => {
			setSelectedGroupIndex(index)
		},
		[setSelectedGroupIndex]
	)

	const setSelectedPeriod = useCallback(
		(index, group) => {
			setSelectedPeriodIndex((s) => {
				const res = [...s]
				res[group] = index
				return res
			})
		},
		[setSelectedPeriodIndex]
	)

	const selectedGroup = data[selectedGroupIndex]
	const selectedPeriod = selectedGroup[selectedPeriodIndex]
	const isReady = loadCount === periods.length

	return (
		<HelmetProvider>
			<Provider
				value={{
					data,
					lang: lang || 'en',
					selectedPeriodIndex,
					setSelectedPeriod,
					selectedGroupIndex,
					setSelectedGroup,
					selectedGroup,
					selectedPeriod,
					loadInc,
					isReady,
					isShort,
					isPortrait,
					altLayout,
				}}
			>
				<Helmet>
					<html lang={lang || 'en'} />
					<title>{metaTags[lang || 'en'].title}</title>
					<meta name='description' content={metaTags[lang || 'en'].description} />
				</Helmet>
				{children}
			</Provider>
		</HelmetProvider>
	)
}

export const useApp = () => useContext(AppContext)

export default AppProvider
