import en from './en'
import fr from './fr'
import de from './de'

const languages = {
	en,
	fr,
	de,
}

export default languages
