import React, { useEffect, useState } from 'react'
import { useTransition, useSpring, animated } from '@react-spring/web'
import { ScaleLoader } from 'react-spinners'
import { useKey } from 'react-use'
import { clamp } from 'lodash'

import { useApp } from 'store/AppStore'

import PeriodSelector from './PeriodSelector'
import ProductSlider from './ProductSlider'

import { Footer, GroupSelect, GroupSwitch, Header, Root, Sidebar } from './Timeline.styled.js'
import BrandLogo from 'components/BrandLogo'

const GroupMeta = ({ groupIndex }) => {
  const { data, selectedPeriodIndex, selectedGroupIndex } = useApp()
  const group = data[groupIndex]

  return (
    <div className="MetaGrid">
      {group.timeline.map(({ meta }, id) => (
        <div
          className="MetaBox"
          aria-selected={selectedGroupIndex === groupIndex && selectedPeriodIndex[groupIndex] === id}
          key={id}
        >
          <div className="BoxInner">
            {meta.text && <div className="Text">{meta.text}</div>}
            {meta.list && (
              <ul className="MetaList">
                {meta.list.map((li, id) => (
                  <li key={id}>{li}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

const Timeline = () => {
  const [periodNav, setPeriodNav] = useState(0)

  const { data, isReady, selectedGroupIndex, selectedPeriodIndex, setSelectedGroup, setSelectedPeriod, altLayout } = useApp()

  const { headerY, footerY, sliderX } = useSpring({
    from: { headerY: '-100%', footerY: '100%', sliderX: '100%', dykScale: 0 },
    to: [{ headerY: isReady ? '0%' : '-100%' }, { footerY: isReady ? '0%' : '100%' }, { sliderX: isReady ? '0%' : '100%' }],
    delay: (key) => {
      if (key === 'dykScale') {
        return 5000
      }
      return 0
    },
  })

  const loaderTransition = useTransition(!isReady, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: isReady ? 0 : 1000,
  })

  useEffect(() => {
    if (periodNav !== 0) {
      const nextSlideIndex = clamp(selectedPeriodIndex[selectedGroupIndex] + periodNav, 0, data[selectedGroupIndex].timeline.length - 1)
      setPeriodNav(0)
      setSelectedPeriod(nextSlideIndex, selectedGroupIndex)
    }
  }, [periodNav, setPeriodNav, selectedPeriodIndex, selectedGroupIndex, data, setSelectedPeriod])

  const goBack = () => setPeriodNav(-1)
  const goForward = () => setPeriodNav(1)
  const changeGroupUp = () => setSelectedGroup(0)
  const changeGroupDown = () => setSelectedGroup(1)

  useKey('ArrowLeft', goBack)
  useKey('ArrowRight', goForward)
  useKey('ArrowUp', changeGroupUp)
  useKey('ArrowDown', changeGroupDown)

  return (
    <Root altLayout={altLayout}>
      {loaderTransition(
        (styles, item) =>
          item && (
            <animated.div
              className="Loader"
              style={styles}
            >
              <ScaleLoader color="currentColor"></ScaleLoader>
            </animated.div>
          )
      )}
      <div className="Inner">
        <Header
          style={{ y: headerY }}
          $altLayout={altLayout}
        >
          <BrandLogo altLayout={altLayout}></BrandLogo>
          <div className="SwitchContainer">
            <GroupSwitch>
              <GroupSelect
                aria-selected={selectedGroupIndex === 1}
                onClick={() => setSelectedGroup(1)}
              >
                @IDC
              </GroupSelect>
              <GroupSelect
                aria-selected={selectedGroupIndex === 0}
                onClick={() => setSelectedGroup(0)}
              >
                @PMI
              </GroupSelect>
            </GroupSwitch>
          </div>
          {/* <a
            href="#"
            className="ButtonPill"
          >
            Explore the PMI Campus
          </a> */}
        </Header>
        <animated.div
          className="Body"
          style={{ x: sliderX, width: `calc(100% - ${altLayout ? 250 : 0}px)` }}
        >
          <ProductSlider groupIndex={0}></ProductSlider>
          <ProductSlider groupIndex={1}></ProductSlider>
        </animated.div>
        <Footer style={{ y: footerY, width: `calc(100% - ${altLayout ? 250 : 0}px)` }}>
          <div className="TimelineSelectorGrid">
            <div
              className="TimelineGroup"
              aria-selected={selectedGroupIndex === 0}
            >
              <PeriodSelector
                groupIndex={0}
                showMeta={!altLayout}
              ></PeriodSelector>
            </div>
            <div
              className="TimelineGroup"
              aria-selected={selectedGroupIndex === 1}
            >
              <PeriodSelector
                groupIndex={1}
                showMeta={!altLayout}
              ></PeriodSelector>
            </div>
          </div>
        </Footer>
        {altLayout && (
          <Sidebar>
            <div className="SidebarGrid">
              <div
                className="SidebarGroup"
                aria-selected={selectedGroupIndex === 0}
              >
                <GroupMeta groupIndex={0}></GroupMeta>
              </div>
              <div
                className="SidebarGroup"
                aria-selected={selectedGroupIndex === 1}
              >
                <GroupMeta groupIndex={1}></GroupMeta>
              </div>
            </div>
          </Sidebar>
        )}
      </div>
    </Root>
  )
}

export default Timeline
