import React from 'react'

import Timeline from 'views/Timeline'

import { Root } from './App.styled'

const App = () => {
  return (
    <Root>
      <Timeline></Timeline>
    </Root>
  )
}

export default App
