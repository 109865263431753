import React from 'react'
import { useBoolean, useMount } from 'react-use'

import { ReactComponent as Logo } from 'assets/brand-logo.svg'

import { Root } from './BrandLogo.styled.js'

const BrandLogo = ({ currentView, isShort, altLayout }) => {
  const [mounted, setMounted] = useBoolean(false)

  useMount(() => {
    setMounted(true)
  })

  return (
    <Root
      show={mounted}
      variant="lightBlue"
      isShort={isShort}
      altLayout={altLayout}
    >
      <Logo></Logo>
    </Root>
  )
}

export default BrandLogo
