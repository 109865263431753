const data = [
	{
		name: 'pmi',
		timeline: [
			{
				id: 1,
				year: '1998-99',
				spritesheet: 'earth_pins',
				frame: {
					width: 280,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: 'Accord launched in USA (known as Oasis in Japan).',
				},
			},
			{
				id: 3,
				year: '2003',
				spritesheet: 'cog_wheel',
				frame: {
					width: 315,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: 'PMI expands R&D on smoke-free products.',
				},
			},
			{
				id: 4,
				year: '2004',
				meta: {
					list: [
						`Neuchâtel selected for R&D (The Cube).`,
						`Pilot Industrial Development Center.`,
					],
				},
				spritesheet: 'building',
				frame: { width: 378, height: 448, numFrames: 125 },
			},
			{
				id: 5,
				year: '2006',
				spritesheet: 'heatbar',
				frame: {
					width: 400,
					height: 640,
					numFrames: 100,
				},
				meta: {
					text: 'Launch of Heatbar by PMI in Switzerland and Australia.',
				},
			},
			{
				id: 2,
				year: '2008',
				spritesheet: 'arrows',
				frame: {
					width: 315,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: 'Spin off of PMI from Altria (& PM USA).',
				},
			},
			{
				id: 6,
				year: '2014',
				spritesheet: 'italy',
				frame: { width: 420, height: 448, numFrames: 100 },
				meta: {
					list: [
						`IQOS city launches in Japan & Italy.`,
						`Investment for a new smoke-free factory in Italy.`,
					],
				},
			},
			{
				id: 7,
				year: '2015',
				spritesheet: 'sticks',
				frame: {
					width: 280,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: 'IQOS launched in Switzerland and available in 5 other countries.',
				},
			},
			{
				id: 11,
				year: '2016',
				spritesheet: 'loader',
				frame: { width: 448, height: 448, numFrames: 125 },
				meta: {
					list: [
						'IQOS available in 19 countries.',
						'More than 1 million customers.',
					],
				},
			},
			{
				id: 12,
				year: '2017',
				meta: {
					list: [
						'IQOS available in more than 25 countries.',
						'More than 3 million customers.',
					],
				},
				spritesheet: 'earth_arrow',
				frame: {
					width: 420,
					height: 448,
					numFrames: 125,
				},
			},
			{
				id: 13,
				year: '2021',
				spritesheet: 'flags',
				frame: {
					width: 350,
					height: 448,
					numFrames: 125,
				},
				meta: {
					text: 'Iluma induction technology launched in Switzerland, Japan & Spain.',
				},
			},
			{
				id: 14,
				year: '2023',
				spritesheet: 'fire',
				frame: { width: 224, height: 448, numFrames: 125 },
				// spritesheet: 'flags',
				// frame: {
				//   width: 350,
				//   height: 448,
				//   numFrames: 125,
				// },
				meta: {
					list: [
						"PMI's smoke-free products available for sale in 80 markets.",
						'Smoke-free products made up 35% of net revenues in Q1 2023.',
						'Estimated 19.4 million adults having switched to IQOS and stopped smoking.',
					],
				},
			},
		],
	},
	{
		name: 'mdc',
		timeline: [
			{
				id: 15,
				year: 'Early 2000',
				spritesheet: 'smoke',
				frame: { width: 280, height: 448, numFrames: 125 },
				meta: {
					text:
						'Sensorial exploration of heated plugs of various tobacco cut fillers using a smoke simulator.',
				},
			},
			{
				id: 16,
				year: '2003-2004',
				spritesheet: 'equip',
				frame: { width: 385, height: 448, numFrames: 125 },
				meta: {
					text:
						'Promising sensorial experience identified using heated tobacco products.',
				},
			},
			{
				id: 17,
				year: '2004',
				spritesheet: 'microscope',
				frame: { width: 287, height: 448, numFrames: 100 },
				meta: {
					list: [
						'Decision to develop smoke-free solutions with a group of 30 scientists.',
						'Laboratories and pilot footprint rented in Yverdon.',
					],
				},
			},
			// {
			//   id: 18,
			//   year: '2005',
			//   spritesheet: 'fire',
			//   frame: { width: 224, height: 448, numFrames: 125 },
			//   meta: {
			//     text: 'Move to Yverdon Heatbar stick production in Onnens',
			//   },
			// },
			{
				id: 19,
				year: '2006',
				spritesheet: 'leaf',
				frame: { width: 224, height: 448, numFrames: 125 },
				meta: {
					text: 'Installation of a cast leaf line in Yverdon.',
				},
			},
			{
				id: 20,
				year: '2009',
				spritesheet: 'building',
				frame: { width: 378, height: 448, numFrames: 125 },
				meta: {
					list: [
						`Move into the Cube & the Industrial Development Center buildings in Neuchâtel.`,
						`Relocation of Yverdon prototyping activities in the newly-constructed Industrial Development Center building.`,
						`First prototyping tobacco crimper.`,
						`Installation of the first semi-industrial combiner.`,
					],
				},
			},
			{
				id: 21,
				year: '2011',
				spritesheet: 'robotic_arm',
				frame: { width: 287, height: 448, numFrames: 100 },
				meta: {
					text: 'Installation of the tobacco stick pack prototyping packing line.',
				},
			},
			{
				id: 22,
				year: '2012',
				spritesheet: 'puzzle',
				frame: { width: 315, height: 448, numFrames: 125 },
				meta: {
					text:
						'Installation of a flexible combiner machine to explore various tobacco stick constructions.',
				},
			},
			{
				id: 23,
				year: '2013',
				spritesheet: 'tester',
				frame: { width: 385, height: 448, numFrames: 125 },
				meta: {
					list: [
						`Installation of first semi-industrial crimper.`,
						`First commercial production for city launches (Japan & Italy).`,
					],
				},
			},
			{
				id: 24,
				year: '2015 May',
				meta: {
					text:
						'First commercial production of heated tobacco sticks for the Swiss market.',
				},
				spritesheet: 'stick',
				frame: { width: 315, height: 448, numFrames: 100 },
			},
			{
				id: 25,
				year: '2016 November',
				spritesheet: 'truck',
				frame: { width: 448, height: 448, numFrames: 125 },
				meta: {
					text:
						'The Industrial Development Center passes the 100 Mio tobacco sticks mark for commercial production.',
				},
			},
			{
				id: 26,
				year: '2017',
				meta: {
					list: [
						'End of commercial production.',
						'The Industrial Development Center returns 100% dedicated prototyping and technologies verification activities.',
					],
				},
				spritesheet: 'lighthouse',
				frame: { width: 140, height: 448, numFrames: 100 },
			},
		],
	},
]

export default data
