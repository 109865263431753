import styled, { css } from 'styled-components/macro'

import { colors } from 'styled/theme.styled'

export const Root = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .rc-scrollbars-thumb.rc-scrollbars-thumb-h {
    background-color: ${colors.purple_300} !important;
  }

  > .MetaGrid {
    display: ${({ showMeta }) => (showMeta ? 'grid' : 'none')};
    padding-top: 24px;
    padding-bottom: 16px;

    > .MetaBox {
      grid-row: 1;
      grid-column: 1;
      width: calc(100% - 32px);
      margin: 0 auto;
      max-width: 450px;
      transition: all 0.35s;

      &[aria-selected='false'] {
        opacity: 0;
        transform: translateY(100%);
      }

      > .BoxInner {
        padding: 16px;
        border-radius: 16px;
        background-color: ${colors.purple_300};
        line-height: 1.2;
        font-size: 18px;

        @media (max-width: 1023px) {
          font-size: 16px;
        }

        .Text {
          white-space: pre-line;
        }
        ul {
          padding-left: 20px;
          li {
            list-style: initial;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
`

export const Periods = styled.div`
  width: 100%;

  .PeriodsInner {
    width: auto;
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
  }

  ${({ hasScrollbars }) =>
    hasScrollbars &&
    css`
      height: 45px;
      .PeriodsInner {
        justify-content: flex-start;
        padding-bottom: 14px;
      }
    `};
`

export const PeriodBox = styled.div`
  position: relative;
  flex: 0 0 auto;
  border-bottom: 1px solid ${colors.purple_400};

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 3px;
    transform: scale(${({ selected }) => (selected ? 1 : 0)});
    background-color: ${colors.purple_600};
    transition: transform 0.2s;
  }

  > .Inner {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px 9px;

    > .Year {
      font-size: 16px;
      font-weight: ${({ selected }) => (selected ? 700 : 400)};
      text-align: center;
      white-space: nowrap;
      color: ${colors.purple_600};
      transition: font-weight 0.25s;
    }
  }
`

export const GroupButton = styled.div`
  padding: 16px;
  border-radius: 999px;
  background-color: ${colors.faintBlue};
  color: ${colors.darkBlue};
  font-size: 12px;
  text-transform: uppercase;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`
