import React, { useEffect, memo } from 'react'
import { useSprings, animated, config } from '@react-spring/web'

import { useApp } from 'store/AppStore'

import { Root } from './ContentLayer.styled.js'

const SPRING_DUR = 400

const ContentLayer = memo(({ year, selected, isPortrait, altLayout }) => {
  const yearLetters = year.replace(/ /g, '\u00a0').split('')
  const numLetters = yearLetters.length
  const del = SPRING_DUR / numLetters

  const [springs, api] = useSprings(numLetters, (index) => ({
    from: {
      transform: `translate(${-index * 25}px, 400px) skew(60deg)`,
      opacity: 0,
      config: config.stiff,
    },
  }))

  useEffect(() => {
    if (selected) {
      api.start((index) => ({
        opacity: 1,
        transform: `translate(0px, 0px) skew(0deg)`,
        delay: index * del,
        reset: true,
      }))
    } else {
      api.start((index) => ({
        opacity: 0,
        transform: `translate(-200px, 0px) skew(-45deg)`,
        delay: (index * del) / 2,
      }))
    }
  }, [selected, api, del])

  return (
    <Root
      selected={selected}
      isPortrait={isPortrait}
      altLayout={altLayout}
    >
      <div className="Year">
        {springs.map((styles, id) => (
          <animated.span
            key={id}
            style={styles}
          >
            {yearLetters[id]}
          </animated.span>
        ))}
      </div>
    </Root>
  )
})

const WrappedContent = (props) => {
  const { isPortrait, altLayout } = useApp()
  return (
    <ContentLayer
      {...props}
      {...{ isPortrait, altLayout }}
    ></ContentLayer>
  )
}

export default WrappedContent
