import styled, { css } from 'styled-components/macro'

import { colors } from 'styled/theme.styled'

export const Root = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	user-select: none;
	mix-blend-mode: darken;

	transform: scale(${({ selected }) => (selected ? 1 : 0.75)});
	transition: all 0.2s;

	> .Year {
		${({ isPortrait, altLayout }) =>
			altLayout
				? css`
						font-size: 6vmin;
				  `
				: isPortrait
				? css`
						font-size: 12vw;
				  `
				: css`
						font-size: 172px;
				  `};
		font-weight: 800;
		text-align: center;
		white-space: nowrap;
		color: ${colors.purple_400};
		font-style: italic;

		> * {
			display: inline-block;
		}
	}
`

export const FeatureButton = styled.div`
	position: absolute;
	bottom: 48px;
	right: calc(50% - 72px);
	width: 48px;
	height: 48px;
	border-radius: 999px;
	background-color: ${colors.darkBlue};
	display: flex;
	justify-content: center;
	align-items: center;
	transform: scale(${({ selected }) => (selected ? 1 : 0)});
	transition: transform 0.3s;

	> svg {
		display: block;
		fill: ${colors.faintBlue};
		width: 24px;
	}

	&:hover {
		cursor: pointer;
	}
`
