import React, { useEffect, memo } from 'react'
import { useDrag } from '@use-gesture/react'
import { useSpring, animated } from '@react-spring/web'
import { clamp } from 'lodash'
import { useMeasure } from 'react-use'

import { useApp } from 'store/AppStore'

import { Root, Slides } from './ProductSlider.styled.js'
import Slide from './Slide'

function areEqual(prev, next) {
  return (
    prev.isPortrait === next.isPortrait &&
    prev.isShort === next.isShort &&
    prev.selected === next.selected &&
    prev.selectedSlideIndex === next.selectedSlideIndex
  )
}

const ProductSlider = memo(
  ({ data, groupIndex, selectedSlideIndex, setSelectedPeriod, selected, isShort, isPortrait }) => {
    const [ref, { width }] = useMeasure()
    const { timeline } = data[groupIndex]

    const [{ x, cursor }, api] = useSpring(() => ({
      x: selectedSlideIndex * width,
      cursor: 'grab',
    }))

    const bind = useDrag(
      ({ dragging, movement: [mx], swipe: [swipeX], cancel }) => {
        if (isPortrait) {
          if (swipeX) {
            const nextSlideIndex = clamp(selectedSlideIndex - swipeX, 0, timeline.length - 1)
            setSelectedPeriod(nextSlideIndex, groupIndex)
            // cancel()
          }
        } else {
          if (dragging) {
            if (Math.abs(mx) > width / 2) {
              const nextSlideIndex = clamp(
                selectedSlideIndex - Math.sign(mx),
                0,
                timeline.length - 1
              )
              setSelectedPeriod(nextSlideIndex, groupIndex)
              api.start({ x: -(nextSlideIndex * width - mx), cursor: 'grab' })
              cancel()
            } else {
              api.start({ x: -(selectedSlideIndex * width - mx), cursor: 'grabbing' })
            }
          } else {
            api.start({ x: -(selectedSlideIndex * width), cursor: 'grab' })
          }
        }
      },
      { axis: 'x' }
    )

    useEffect(() => {
      api.start({ x: -(selectedSlideIndex * width) })
    }, [selectedSlideIndex, width, api])

    return (
      <Root
        $isShort={isShort}
        $isPortrait={isPortrait}
        aria-selected={selected}
        {...bind()}
        style={{ cursor }}
      >
        <Slides
          style={{ x }}
          ref={ref}
        >
          {timeline.map((period, id) => (
            <Slide
              key={id}
              {...period}
              selected={selectedSlideIndex === id}
              visible={Math.abs(selectedSlideIndex - id) < 2}
            ></Slide>
          ))}
        </Slides>
      </Root>
    )
  },
  areEqual
)

const WrappedSlider = (props) => {
  const { data, selectedPeriodIndex, selectedGroupIndex, setSelectedPeriod, isShort, isPortrait } =
    useApp()
  return (
    <ProductSlider
      {...props}
      {...{ data, selectedPeriodIndex, selectedGroupIndex, setSelectedPeriod, isShort, isPortrait }}
      selected={props.groupIndex === selectedGroupIndex}
      selectedSlideIndex={selectedPeriodIndex[props.groupIndex]}
    ></ProductSlider>
  )
}

export default animated(WrappedSlider)
