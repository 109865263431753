import styled, { css } from 'styled-components/macro'
import { animated } from '@react-spring/web'

export const Slides = styled(animated.div)`
  height: 100%;
  max-height: 680px;
  padding: 20px 0;
  display: flex;
  margin: auto;
  pointer-events: none;
`

export const Root = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  touch-action: none;
  pointer-events: all;

  ${({ $isPortrait }) => css`
    ${Slides} {
      ${$isPortrait
        ? `
          width: 100%;
          min-width: 100%;
          `
        : `
          width: 33.333%;
          min-width: 560px;
          `}
    }
  `}

  transition: all 0.5s 0.3s;

  &[aria-selected='false'] {
    opacity: 0;
    transform: translateX(100%);
    transition-delay: 0.2s;
  }

  &:first-child {
    &[aria-selected='false'] {
      opacity: 0;
      transform: translateX(-100%);
    }
  }
`
