// import device_1 from './device_1.png'
// import device_2 from './device_2.png'
// import device_3 from './device_3.png'
// import device_4 from './device_4.png'
// import device_5 from './device_5.png'
// import device_6 from './device_6.png'
// import device_7 from './device_7.png'
// import device_8 from './device_8.png'
// import device_9 from './device_9.png'
import lighthouse from './lighthouse.png'
import microscope from './microscope.png'
import cube from './cube.png'
import earth from './earth.png'
import mint from './mint.png'
// import geotag from './geotag.png'
import robotic_arm from './robotic_arm.png'
import stick from './stick.png'
import equip from './equip.png'
import puzzle from './puzzle.png'
import loader from './loader.png'
import building from './building.png'
import truck from './truck.png'
import tester from './tester.png'
import arrows from './arrows.png'
import cog_wheel from './cog_wheel.png'
import earth_arrow from './earth_arrow.png'
import earth_pins from './earth_pins.png'
import flags from './flags.png'
import sticks from './sticks.png'
import fire from './fire.png'
import italy from './italy.png'
import leaf from './leaf.png'
import smoke from './smoke.png'
import heatbar from './heatbar.png'

const spritesheets = {
  // device_1,
  // device_2,
  // device_4,
  // device_5,
  // device_6,
  // device_7,
  // device_8,
  // device_9,
  heatbar,
  lighthouse,
  microscope,
  cube,
  earth,
  mint,
  // geotag,
  robotic_arm,
  stick,
  equip,
  puzzle,
  loader,
  building,
  tester,
  truck,
  arrows,
  cog_wheel,
  earth_arrow,
  earth_pins,
  flags,
  sticks,
  fire,
  italy,
  leaf,
  smoke,
}

export default spritesheets
