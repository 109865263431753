import styled, { css } from 'styled-components/macro'
import { animated } from '@react-spring/web'

import { colors } from 'styled/theme.styled'

export const Root = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > .Inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    transition: all, 0.3s;
    filter: blur(${({ blur }) => (blur ? 8 : 0)}px);

    > .Body {
      position: relative;
      ${({ altLayout }) =>
        altLayout &&
        css`
          margin-top: -80px;
        `};
    }
  }

  .Loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.darkBlue};
  }
`
export const GroupSwitch = styled.div`
  background-color: ${colors.purple_300};
  padding: 8px;
  border-radius: 16px;
  display: flex;
`

export const GroupSelect = styled.div`
  border-radius: 12px;
  font-size: 18px;
  padding: 16px 24px;

  &[aria-selected='true'] {
    background-color: ${colors.purple_600};
    color: ${colors.white};
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1023px) {
    padding: 10px 18px;
  }
`
export const Header = styled(animated.div)`
  width: 100%;
  padding: 52px 52px 26px;
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;

  > .ButtonPill {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    color: ${colors.white};
    background-color: ${colors.purple_600};
    border-radius: 999px;
    font-size: 24px;
    line-height: 1;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  ${({ $altLayout }) =>
    $altLayout &&
    css`
      padding: 0 20px;
      height: 80px;

      ${GroupSwitch} {
        width: 218px;
        height: 60px;
        justify-content: space-between;
      }
      ${GroupSelect} {
        flex: 0 1 50%;
        height: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `};

  @media (max-width: 1023px) {
    padding: 20px;
  }
`

export const Footer = styled(animated.div)`
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .TimelineSelectorGrid {
    display: grid;
    width: 100%;

    > .TimelineGroup {
      grid-row: 1;
      grid-column: 1;
      transition: all 0.35s;

      &[aria-selected='false'] {
        opacity: 0;
        transform: translateY(100%);
        transition-delay: 0.2s;
      }
    }
  }
`

export const Sidebar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  z-index: -1;
  padding-top: 80px;

  > .SidebarGrid {
    display: grid;
    width: 100%;
    height: 100%;
    /* align-items: end; */

    > .SidebarGroup {
      grid-row: 1;
      grid-column: 1;
      transition: all 0.35s;
      min-height: 0;
      max-height: 100%;
      overflow-x: hidden;

      &[aria-selected='false'] {
        opacity: 0;
        transform: translateX(100%);
        transition-delay: 0.2s;
      }
    }
  }

  .MetaGrid {
    display: grid;
    min-height: 0;
    max-height: 100%;
    overflow-x: hidden;

    > .MetaBox {
      grid-row: 1;
      grid-column: 1;
      width: calc(100% - 32px);
      min-height: 0;
      max-height: 100%;
      margin: 0 auto;
      max-width: 450px;
      transition: all 0.35s;

      &[aria-selected='false'] {
        opacity: 0;
        transform: translateX(100%);
      }

      > .BoxInner {
        padding: 16px;
        border-radius: 16px;
        background-color: ${colors.purple_300};
        line-height: 1.2;
        font-size: 16px;

        ul {
          padding-left: 20px;
          li {
            list-style: initial;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
`
