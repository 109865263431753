import React, { memo, useEffect, useRef, useState } from 'react'
import { Scrollbars } from 'rc-scrollbars'

import useSpring from 'react-use/lib/useSpring'

import { useApp } from 'store/AppStore'

import { Root, Periods, PeriodBox } from './PeriodSelector.styled.js'
import { useMedia } from 'react-use'

const TimelinePeriod = ({ selected, year, handleSelected, productIndex, groupIndex }) => {
  const { setSelectedPeriod } = useApp()

  const boxRef = useRef()

  useEffect(() => {
    if (selected) {
      handleSelected(boxRef.current.offsetLeft)
    } else {
      boxRef.current.blur()
    }
  }, [selected, handleSelected])

  return (
    <PeriodBox
      ref={boxRef}
      onClick={() => setSelectedPeriod(productIndex, groupIndex)}
      selected={selected}
    >
      <div className="Inner">
        <div className="Year">{year}</div>
      </div>
    </PeriodBox>
  )
}

const timelineMap = (timeline, groupIndex, selectedIndex, handleSelected) => {
  return timeline.map((period, id) => (
    <TimelinePeriod
      key={period.id}
      {...period}
      selected={selectedIndex === id}
      productIndex={id}
      groupIndex={groupIndex}
      handleSelected={handleSelected}
    ></TimelinePeriod>
  ))
}

const PeriodSelector = ({ groupIndex, showMeta }) => {
  const [scrollPos, setScrollPos] = useState(0)
  const hasScrollbars = useMedia(`(max-width: 1150px)`)
  const springPos = useSpring(scrollPos, 0.5, 5)
  const { data, selectedPeriodIndex, selectedGroupIndex } = useApp()
  const group = data[groupIndex]

  const scrollRef = useRef()

  const scrollTo = (pos) => {
    setScrollPos(pos - 20)
  }

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft(springPos)
    }
  }, [springPos])

  return (
    <Root showMeta={showMeta}>
      <Periods hasScrollbars={hasScrollbars}>
        {hasScrollbars ? (
          <Scrollbars ref={scrollRef}>
            <div className="PeriodsInner">
              {timelineMap(group.timeline, groupIndex, selectedPeriodIndex[groupIndex], scrollTo)}
            </div>
          </Scrollbars>
        ) : (
          <div className="PeriodsInner">
            {timelineMap(group.timeline, groupIndex, selectedPeriodIndex[groupIndex], scrollTo)}
          </div>
        )}
      </Periods>
      <div className="MetaGrid">
        {showMeta &&
          group.timeline.map(({ meta }, id) => (
            <div
              className="MetaBox"
              aria-selected={
                selectedGroupIndex === groupIndex && selectedPeriodIndex[groupIndex] === id
              }
              key={id}
            >
              <div className="BoxInner">
                {meta.text && <div className="Text">{meta.text}</div>}
                {meta.list && (
                  <ul className="MetaList">
                    {meta.list.map((li, id) => (
                      <li key={id}>{li}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
      </div>
    </Root>
  )
}

export default memo(PeriodSelector)
